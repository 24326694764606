<i18n>
  {
    "de": {
      "exportCsvButton": "Daten (CSV)",
      "exportPngButton": "Bild (PNG)"
    }
  }
</i18n>

<template>
  <div class="c-exportable-line-chart">
    <LineChart ref="chart" class="chart-container" :chart-data="chartData" :chart-options="chartOptions" />
    <div
      v-if="exportable"
      ref="exportWrapper"
      class="export-wrapper"
      :style="{ width: `${imageSize.width}px`, height: `${imageSize.height}px` }"
    >
      <canvas ref="exportCanvas" />
    </div>
    <div v-if="exportable" class="download-buttons">
      <Button class-name="button--ghost" icon="download" :text="$t('exportCsvButton')" small @click="exportAsCsv" />
      <Button class-name="button--ghost" icon="download" :text="$t('exportPngButton')" small @click="exportAsPng" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { Line as LineChart } from 'vue-chartjs/legacy'

import exportService from '@/services/export-service.js'

import Button from '@/components/cui/inputs/Button.vue'

export default {
  components: {
    LineChart,
    Button,
  },

  props: {
    chartData: {
      type: Object,
    },
    options: {
      type: Object,
    },
    exportable: {
      type: Boolean,
      default: false,
    },
    imageSize: {
      type: Object,
      default: () => ({
        width: 720,
        height: 360,
      }),
    },
  },

  computed: {
    chartOptions() {
      let options = _.cloneDeep(this.options)
      if (!options?.plugins?.annotation) _.set(options, 'plugins.annotation.annotations', {})
      return options
    },
  },

  mounted() {
    this.chartInstance = this.$refs.chart
  },

  methods: {
    exportAsCsv() {
      let data = exportService.chartToCsv(this.chartData, this.options)
      exportService.saveCsv(data.join('\n'), 'export.csv')
    },

    exportAsPng() {
      // Render the export wrapper
      this.$refs.exportWrapper.style.display = 'block'

      const { chart } = exportService.chartToExportCanvas(
        this.chartInstance.getCurrentChart(),
        this.$refs.exportCanvas,
        this.chartData,
        this.options,
        this.imageSize
      )

      if (chart) {
        exportService.savePng(chart, 'chart.png')
        // Hide the export wrapper after exporting
        this.$refs.exportWrapper.style.display = 'none'
        // Remove export chart instance
        chart.destroy()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-exportable-line-chart {
  position: relative;

  & .download-buttons {
    display: flex;
    justify-content: center;
    height: auto;
    padding-top: 10px;
  }

  & .chart-container {
    position: relative;
  }

  & .export-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    visibility: hidden;
  }
}
</style>
