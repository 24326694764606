<i18n>
{
  "de": {
    "pe": "Primärenergie",
    "peArea": "Primärenergie (Fläche)",
    "peCount": "Primärenergie (Anzahl)",
    "rh": "Raumwärme",
    "rhArea": "Raumwärme (Fläche)",
    "rhCount": "Raumwärme (Anzahl)"
  }
}
</i18n>

<template>
  <Tabs group="efficiency-classes-section-tabs" class="c-efficiency-classes-section">
    <Tab :title="$t('pe')">
      <EfficiencyClassChart
        key="efficiency-class-chart-pe"
        :kpi-type="'pe_ratio'"
        :portfolio="portfolio"
        :query="query"
        :scenario="scenario"
      />
    </Tab>
    <Tab :title="$t('peArea')">
      <EfficiencyClassAreaChart
        key="efficiency-class-chart-pe-area"
        :kpi-type="'pe_ratio'"
        :portfolio="portfolio"
        :query="query"
        :scenario="scenario"
      />
    </Tab>
    <Tab :title="$t('peCount')">
      <EfficiencyClassCountChart
        key="efficiency-class-chart-pe-count"
        :kpi-type="'pe_ratio'"
        :portfolio="portfolio"
        :query="query"
        :scenario="scenario"
      />
    </Tab>
    <Tab :title="$t('rh')">
      <EfficiencyClassChart
        key="efficiency-class-chart-rh"
        :kpi-type="'rh_ratio'"
        :portfolio="portfolio"
        :query="query"
        :scenario="scenario"
      />
    </Tab>
    <Tab :title="$t('rhArea')">
      <EfficiencyClassAreaChart
        key="efficiency-class-chart-rh-area"
        :kpi-type="'rh_ratio'"
        :portfolio="portfolio"
        :query="query"
        :scenario="scenario"
      />
    </Tab>
    <Tab :title="$t('rhCount')">
      <EfficiencyClassCountChart
        key="efficiency-class-chart-rh-count"
        :kpi-type="'rh_ratio'"
        :portfolio="portfolio"
        :query="query"
        :scenario="scenario"
      />
    </Tab>
  </Tabs>
</template>

<script>
import { mapGetters } from 'vuex'

import Tabs from '@/components/shared/Tabs.vue'
import Tab from '@/components/shared/Tab.vue'
import EfficiencyClassChart from '@/components/report/charts/EfficiencyClassChart.vue'
import EfficiencyClassAreaChart from '@/components/report/charts/EfficiencyClassAreaChart.vue'
import EfficiencyClassCountChart from '@/components/report/charts/EfficiencyClassCountChart.vue'

export default {
  components: {
    EfficiencyClassChart,
    EfficiencyClassAreaChart,
    EfficiencyClassCountChart,
    Tabs,
    Tab,
  },

  props: {
    portfolio: {
      type: Object,
    },
    scenario: {
      type: Object,
    },
  },

  computed: {
    ...mapGetters({
      query: 'query/get',
    }),
  },
}
</script>
