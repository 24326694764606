<i18n>
{
  "de": {
    "envelopeHeatDemand": "Heizwärmebedarf",
    "heatingType": "Anteil Wärmeerzeuger"
  }
}
</i18n>

<template>
  <Tabs group="heat-section-tabs" class="c-heat-section">
    <Tab :title="$t('heatingType')">
      <HeatingTypeChart key="heat-by-type-chart" :portfolio="portfolio" :query="query" :scenario="scenario" />
    </Tab>
    <Tab :title="$t('envelopeHeatDemand')">
      <KpiCompareScenarioChart
        key="heat-scenarios-chart"
        :kpi-type="'rh_demand'"
        :portfolio="portfolio"
        :query="query"
      />
    </Tab>
  </Tabs>
</template>

<script>
import { mapGetters } from 'vuex'

import Tabs from '@/components/shared/Tabs.vue'
import Tab from '@/components/shared/Tab.vue'
import KpiCompareScenarioChart from '@/components/report/charts/KpiCompareScenarioChart.vue'
import HeatingTypeChart from '@/components/report/charts/HeatingTypeChart.vue'

export default {
  components: {
    KpiCompareScenarioChart,
    HeatingTypeChart,
    Tabs,
    Tab,
  },

  props: {
    portfolio: {
      type: Object,
    },
    scenario: {
      type: Object,
    },
  },

  computed: {
    ...mapGetters({
      query: 'query/get',
    }),
  },
}
</script>
