<i18n>
{
  "de": {
    "yLabel": "Investitionskosten (CHF)",
    "measureWINDOWS": "Fensterersatz",
    "measureROOF": "Sanierung Dach",
    "measureBASEMENT": "Sanierung Kellerdecke",
    "measureWALLS": "Sanierung Fassade",
    "measureHEATING": "Heizungsersatz",
    "measurePV": "Installation Photovoltaik",
    "measureELECTRICITY": "Änderung Netzanschluss"
  }
}
</i18n>

<template>
  <BarChart :chart-data="chartData" :options="options" exportable @mounted="onChartMounted" />
</template>

<script>
import _ from 'lodash'

import ChartOptionsMixin from '@/pages/vuex-mixins/ChartOptionsMixin.vue'

import BarChart from '@/components/shared/charts/BarChart.vue'

import colorPalettes from '@/services/color-palettes.js'

export default {
  mixins: [
    //
    ChartOptionsMixin,
  ],

  components: {
    BarChart,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
    selectedYear: {
      type: Number,
    },
  },

  data() {
    return {
      chartRef: null,
    }
  },

  computed: {
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        /* aspectRatio: 3, */
        scales: {
          xAxis: {
            stacked: true,
          },
          yAxis: {
            stacked: true,
            beginAtZero: true,
            ticks: {
              callback: (value) => this.formatNumber(value, 0),
            },
            title: {
              display: true,
              text: this.$t('yLabel'),
            },
          },
        },
        plugins: {
          tooltip: {
            mode: 'index',
            callbacks: {
              title: (items) =>
                this.getTooltipTitleWithTotal(items, { title: items[0].label, unit: 'CHF', suffix: false }),
              label: (item) => this.getTooltipLabel(item, { unit: 'CHF' }),
            },
          },
          annotation: {
            drawTime: 'beforeDraw',
            annotations: this.data.years.map((year, index) => ({
              type: 'box',
              xMin: index - 0.5,
              xMax: index + 0.5,
              yMin: 'start',
              yMax: 'end',
              borderWidth: 1,
              borderColor: 'transparent',
              backgroundColor: 'transparent',
              click: () => this.onSelectionChange(year),
            })),
          },
          legend: {
            position: 'bottom',
            align: 'start',
          },
        },
      }
    },

    chartData() {
      const electricityCosts = this.data.projections.electricity?.costs.reduce((a, b) => a + b, 0) || 0
      const heatingCosts = this.data.projections.heating?.costs.reduce((a, b) => a + b, 0) || 0
      const pvCosts = this.data.projections.pv?.costs.reduce((a, b) => a + b, 0) || 0
      const wallsCosts = this.data.projections.walls?.costs.reduce((a, b) => a + b, 0) || 0
      const windowsCosts = this.data.projections.windows?.costs.reduce((a, b) => a + b, 0) || 0
      const roofCosts = this.data.projections.roof?.costs.reduce((a, b) => a + b, 0) || 0
      const basementCosts = this.data.projections.basement?.costs.reduce((a, b) => a + b, 0) || 0
      const totalCosts =
        electricityCosts + heatingCosts + pvCosts + wallsCosts + windowsCosts + roofCosts + basementCosts

      const datasets = []

      // TODO: add zones (currently not possible since zone actions don't have costs)

      if (heatingCosts > 0) {
        datasets.push({
          label: this.$t('measureHEATING'),
          lineTension: 0.1,
          borderWidth: 0,
          pointRadius: 0,
          pointHitRadius: 10,
          data: this.data.projections.heating?.costs || [],
          backgroundColor: colorPalettes.measureColors['HEATING'],
          borderColor: colorPalettes.measureColors['HEATING'],
        })
      }

      if (wallsCosts > 0) {
        datasets.push({
          label: this.$t('measureWALLS'),
          lineTension: 0.1,
          borderWidth: 0,
          pointRadius: 0,
          pointHitRadius: 10,
          data: this.data.projections.walls?.costs || [],
          backgroundColor: colorPalettes.measureColors['WALLS'],
          borderColor: colorPalettes.measureColors['WALLS'],
        })
      }
      if (windowsCosts > 0) {
        datasets.push({
          label: this.$t('measureWINDOWS'),
          lineTension: 0.1,
          borderWidth: 0,
          pointRadius: 0,
          pointHitRadius: 10,
          data: this.data.projections.windows?.costs || [],
          backgroundColor: colorPalettes.measureColors['WINDOWS'],
          borderColor: colorPalettes.measureColors['WINDOWS'],
        })
      }
      if (roofCosts > 0) {
        datasets.push({
          label: this.$t('measureROOF'),
          lineTension: 0.1,
          borderWidth: 0,
          pointRadius: 0,
          pointHitRadius: 10,
          data: this.data.projections.roof?.costs || [],
          backgroundColor: colorPalettes.measureColors['ROOF'],
          borderColor: colorPalettes.measureColors['ROOF'],
        })
      }
      if (basementCosts > 0) {
        datasets.push({
          label: this.$t('measureBASEMENT'),
          lineTension: 0.1,
          borderWidth: 0,
          pointRadius: 0,
          pointHitRadius: 10,
          data: this.data.projections.basement?.costs || [],
          backgroundColor: colorPalettes.measureColors['BASEMENT'],
          borderColor: colorPalettes.measureColors['BASEMENT'],
        })
      }
      if (pvCosts > 0) {
        datasets.push({
          label: this.$t('measurePV'),
          lineTension: 0.1,
          borderWidth: 0,
          pointRadius: 0,
          pointHitRadius: 10,
          data: this.data.projections.pv?.costs || [],
          backgroundColor: colorPalettes.measureColors['PV'],
          borderColor: colorPalettes.measureColors['PV'],
        })
      }
      if (electricityCosts > 0) {
        datasets.push({
          label: this.$t('measureELECTRICITY'),
          lineTension: 0.1,
          borderWidth: 0,
          pointRadius: 0,
          pointHitRadius: 10,
          data: this.data.projections.electricity?.costs || [],
          backgroundColor: colorPalettes.measureColors['ELECTRICITY'],
          borderColor: colorPalettes.measureColors['ELECTRICITY'],
        })
      }

      return {
        labels: this.data.years,
        datasets:
          totalCosts > 0
            ? datasets
            : [
                // Empty placeholder dataset to show chart
                {
                  label: '',
                  data: this.data.years.map(() => 0),
                  backgroundColor: 'rgba(0, 0, 0, 0)',
                  borderColor: 'rgba(0, 0, 0, 0)',
                },
              ],
      }
    },
  },

  watch: {
    selectedYear() {
      const chart = this.chartRef.getCurrentChart()
      const annotations = chart.options.plugins.annotation.annotations

      if (this.selectedYear) {
        const yearIndex = _.findIndex(this.chartData.labels, (year) => year === this.selectedYear)
        let lastSelected = annotations[this.lastSelection]
        let selected = annotations[yearIndex]

        if (lastSelected) {
          lastSelected.backgroundColor = 'transparent'
        }

        selected.backgroundColor = 'rgba(246, 190, 79, 0.5)'
        this.lastSelection = yearIndex
      } else {
        let lastSelected = annotations[this.lastSelection]

        if (lastSelected) {
          lastSelected.backgroundColor = 'transparent'
          this.lastSelection = null
        }
      }
      chart.update()
    },
  },

  mounted() {
    this.lastSelection = null
  },

  methods: {
    onChartMounted(chart) {
      this.chartRef = chart
    },

    onSelectionChange(year) {
      this.$emit('selection-change', year)
    },
  },
}
</script>
