<template>
  <div :key="group">
    <div class="tabs">
      <template v-for="(tab, index) in tabs">
        <a
          :key="`${group}-${index}`"
          class="tabs-title"
          :class="{ selected: index === selectedTabIndex }"
          @click="setSelectedTab(index)"
        >
          {{ tab.title }}
        </a>
      </template>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    group: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      tabs: [],
    }
  },

  computed: {
    ...mapGetters({
      __getSelectedTabIndex: 'ui/getSelectedTabIndex',
    }),

    selectedTabIndex() {
      return this.__getSelectedTabIndex(this.group)
    },
  },

  watch: {
    selectedTabIndex(index) {
      this.setActiveTab(index)
    },
  },

  created() {
    this.tabs = this.$children
  },

  mounted() {
    this.setSelectedTab(this.selectedTabIndex)
  },

  methods: {
    ...mapActions({
      setSelectedTabIndex: 'ui/setSelectedTabIndex',
    }),

    setSelectedTab(index) {
      this.setSelectedTabIndex({ group: this.group, index })
      this.setActiveTab(index)
    },

    setActiveTab(index) {
      this.tabs.forEach((tab, i) => {
        tab.isActive = index === i
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.tabs {
  margin-bottom: var(--spacing-s);
  max-width: 580px;

  & > a {
    display: inline-block;
    padding: var(--spacing-xs);
    margin: var(--spacing-xs);
    cursor: pointer;
  }

  & > a.selected {
    border-bottom: 2px solid var(--secondary-text-color);
  }
}
</style>
