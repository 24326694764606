<i18n>
{
  "de": {
    "identifier": "Identifikation",
    "description": "Bezeichnung",
		"year": "Jahr",
		"measure": "Massnahme",
		"costs": "Investitionskosten",
		"s12e": "Scope 1-2",
		"reductionCosts": "Vermeidungskosten",
    "measures": {
			"windows": "Fensterersatz",
			"roof": "Sanierung Dach",
			"basement": "Sanierung Kellerdecke",
			"walls": "Sanierung Fassade",
			"heating": "Heizungsersatz",
			"pv": "Installation Photovoltaik",
      "electricity": "Änderung Netzanschluss",
      "zones": "Änderung von Flächen und Nutzung"
		},
		"allMeasures": "Alle Massnahmen",
		"measuresIn": "Massnahme | Massnahmen",
		"reduction": "Reduktion",
		"totalMeasure": "Gesamt",
		"total": "Total",
		"reset": "Zurücksetzen",
    "noData": "Keine Massnahmen",
    "reductionAmount": "Reduktion: {amount} kg CO₂e",
    "noChange": "Keine Veränderung",
    "increaseAmount": "Zunahme: {amount} kg CO₂e",
    "s12eInfobox": "Scope 1-2 Emissionen",
    "s12eInfoboxText": "Direkte Emissionen in t CO₂e nach der Massnahme. Die Reduktion (oder Zunahme) ist die Differenz zwischen den Scope 1-2 Emissionen vor und nach der Massnahme."
  }
}
</i18n>

<template>
  <div class="c-costs-overview">
    <AsyncContentContainer :content-state="contentState" class="costs-overview-chart-container">
      <template #default>
        <FaultsContainer :faults="data.faulty" :portfolio="portfolio" />
        <CostChart
          :data="data"
          :selected-year="selectedYear"
          class="costs-overview-chart"
          @selection-change="onYearSelectionChange"
        />
      </template>
    </AsyncContentContainer>

    <div class="costs-overview-table-container">
      <!-- Header -->
      <div class="overview-header">
        <!-- Title -->
        <h2>
          {{
            selectedYear
              ? `${selectedYear}: ${tableData.length} ${$tc('measuresIn', tableData.length)}`
              : $t('allMeasures')
          }}
        </h2>

        <!-- Reset button -->
        <Button v-if="selectedYear" icon="refresh-ccw" :text="$t('reset')" @click="onReset" />
      </div>

      <DetailList v-if="tableData.length > 0" has-header>
        <!-- Headers -->
        <template #header>
          <HeaderEntry :title="$t('identifier')" class="id" />
          <HeaderEntry :title="$t('description')" class="name" />
          <HeaderEntry :title="$t('year')" class="year" />
          <HeaderEntry :title="$t('measure')" class="measures" />
          <HeaderEntry
            :title="$t('s12e')"
            :infobox-title="$t('s12eInfobox')"
            :infobox-text="$t('s12eInfoboxText')"
            :unit="`t CO₂e (${$t('reduction')})`"
            class="emissions"
            align-right
          />
          <HeaderEntry :title="$t('costs')" unit="CHF" class="costs" align-right />
          <HeaderEntry :title="$t('reductionCosts')" unit="CHF/kg CO₂e" class="reduction-costs" align-right />
        </template>

        <!-- Rows, data -->
        <template>
          <li v-for="item in tableData" :key="item.id">
            <!-- ID (Identifier) -->
            <div class="id">
              <router-link
                :to="{ name: 'buildingDetails', params: { portfolio_id: portfolio.id, building_id: item.building_id } }"
              >
                <strong>
                  {{ item.building_identifier }}
                </strong>
              </router-link>
            </div>

            <!-- Name (Description) -->
            <div class="name">
              <router-link
                :to="{ name: 'buildingDetails', params: { portfolio_id: portfolio.id, building_id: item.building_id } }"
              >
                <strong>
                  {{ item.building_description }}
                </strong>
              </router-link>
            </div>

            <!-- Year -->
            <div class="year">
              <strong>
                {{ item.year }}
              </strong>
            </div>

            <!-- Measures -->
            <div class="measures align-right">
              <div class="measures-list align-left">
                <strong>
                  {{ $t('totalMeasure') }}
                </strong>
              </div>
              <div
                v-for="(item, index) in getBuildingMeasures(item.actions)"
                :key="`${index}-${item.name}`"
                class="measures-list align-left no-wrap"
              >
                <span :style="{ color: item.color }">■</span>
                <span>
                  {{ item.name }}
                </span>
              </div>
            </div>

            <!-- Scope 1-2 Emissions -->
            <div class="emissions align-right">
              <strong>
                {{ `${formatNumberWithThreshold(item.next_s12e / 1000, 2, 1, 1)} ` }}
              </strong>

              <!-- Percentage -->
              <VPopover placement="bottom" trigger="hover">
                <span v-if="item.difference.rawValue !== 0" class="gray">
                  {{ `(${item.difference.icon} ${formatNumber(item.difference.percentageValue, 1)}%)` }}
                </span>
                <span v-else class="gray">{{ `(-)` }}</span>

                <template slot="popover">
                  <main v-if="item.difference.rawValue > 0">
                    {{ $t('reductionAmount', { amount: formatNumber(item.difference.rawValue, 1) }) }}
                  </main>
                  <main v-else-if="item.difference.rawValue === 0">
                    {{ $t('noChange') }}
                  </main>
                  <main v-else>
                    {{
                      $t('increaseAmount', {
                        amount: formatNumber(Math.abs(item.difference.rawValue), 1),
                      })
                    }}
                  </main>
                </template>
              </VPopover>
            </div>

            <!-- Costs -->
            <div class="costs align-right">
              <div class="align-right">
                <strong>
                  {{ formatNumber(getBuildingMeasuresTotalCosts(item.costs), 0) }}
                </strong>
              </div>
              <div v-for="(value, index) in getBuildingMeasuresCosts(item.costs)" :key="`${index}-${value}`">
                {{ formatNumber(value, 0) }}
              </div>
            </div>

            <!-- Reduction Costs -->
            <div class="reduction-costs align-right">
              <strong v-if="item.reduction_costs > 0">
                {{ formatNumberWithThreshold(item.reduction_costs, 0, 10, -1) }}
              </strong>
              <strong v-else>
                {{ '-' }}
              </strong>
            </div>
          </li>

          <!-- Summary row -->
          <li v-if="tableData.length > 0" class="summary-row">
            <div class="id">
              <strong>
                {{ $t('total') }}
              </strong>
            </div>
            <div class="name" />
            <div class="year" />
            <div class="measures" />
            <div class="emissions align-right" />
            <div class="costs align-right">
              <strong>
                {{ formatNumber(summaryData.investmentCosts, 0) }}
              </strong>
            </div>
            <div class="reduction-costs align-right">
              <strong v-if="summaryData.reductionCosts > 0">
                {{ formatNumberWithThreshold(summaryData.reductionCosts, 0, 10, -1) }}
              </strong>
              <strong v-else>
                {{ '-' }}
              </strong>
            </div>
          </li>
        </template>
      </DetailList>

      <!-- No data row -->
      <div v-if="tableData.length === 0" class="no-data">
        {{ $t('noData') }}
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { VPopover } from 'v-tooltip'

import compassApi from '@/services/compass-api.js'
import colorPalettes from '@/services/color-palettes.js'

import AsyncContentMixin from '@/components/shared/AsyncContentMixin.vue'

import Button from '@/components/cui/inputs/Button.vue'
import AsyncContentContainer from '@/components/shared/AsyncContentContainer.vue'
import FaultsContainer from '@/components/report/charts/FaultsContainer.vue'
import CostChart from '@/components/report/charts/CostChart.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import HeaderEntry from '@/components/shared/lists/HeaderEntry.vue'

export default {
  mixins: [
    //
    AsyncContentMixin,
  ],

  components: {
    //
    Button,
    AsyncContentContainer,
    FaultsContainer,
    CostChart,
    DetailList,
    HeaderEntry,
    VPopover,
  },

  props: {
    portfolio: {
      type: Object,
    },
    scenario: {
      type: Object,
    },
  },

  data() {
    return {
      data: null,
      selectedYear: null,
    }
  },

  computed: {
    ...mapGetters({
      query: 'query/get',
    }),

    tableData() {
      const result =
        this.data?.measures
          .map((measure) => {
            const previousS12e = measure.previous_s12e || 0
            // const previousS12et = previousS12e ? previousS12e / 1000 : 0
            const nextS12e = measure.next_s12e || 0
            // const nextS12et = nextS12e ? nextS12e / 1000 : 0
            const difference = this.getDifference(previousS12e, nextS12e)
            const investmentCosts = this.getBuildingMeasuresTotalCosts(measure.costs)
            const reductionCosts = difference.rawValue !== 0 ? investmentCosts / difference.rawValue : 0

            return {
              ...measure,
              previous_s12e: previousS12e,
              next_s12e: nextS12e,
              difference: difference,
              investment_costs: investmentCosts,
              reduction_costs: reductionCosts,
            }
          })
          // Sort by building identifier and year
          .sort((a, b) => a.building_identifier.localeCompare(b.building_identifier) || a.year - b.year) || []

      return !this.selectedYear ? result : result.filter((measure) => measure.year === this.selectedYear)
    },

    summaryData() {
      const investmentCosts = _.sumBy(this.tableData, 'investment_costs')
      const nextS12e = _.sumBy(this.tableData, 'next_s12e')
      const previousS12e = _.sumBy(this.tableData, 'previous_s12e')
      const difference = this.getDifference(previousS12e, nextS12e)
      const reductionCosts = difference.rawValue !== 0 ? investmentCosts / difference.rawValue : 0

      return {
        // TODO: Add total co2 at end state (sum of end states), and co2 reduction compared to start state (sum of start states)
        investmentCosts,
        reductionCosts,
      }
    },
  },

  watch: {
    portfolio() {
      this.refreshContent()
    },
    scenario() {
      this.refreshContent()
    },
    query() {
      this.refreshContent()
    },
  },

  methods: {
    //
    async loadContentHandler(signal) {
      const query = JSON.stringify(this.query)
      this.data = null
      this.data = await compassApi.call(
        `/projections/${this.portfolio.id}/costs/by_type/${this.scenario.id}?query=${query}`,
        'GET',
        null,
        signal
      )
    },

    //
    getBuildingMeasures(data) {
      const result = []
      _.keys(data).forEach((key) => {
        if (data[key]) {
          result.push({ name: this.$t(`measures.${key}`), color: colorPalettes.measureColors[key.toUpperCase()] })
        }
      })
      return result
    },

    //
    getBuildingMeasuresCosts(data) {
      const result = []
      _.keys(data).forEach((key) => {
        if (data[key]) {
          result.push(data[key].costs)
        }
      })
      return result
    },

    //
    getBuildingMeasuresTotalCosts(data) {
      let totalCost = 0
      _.keys(data).forEach((key) => {
        if (data[key]) {
          totalCost += data[key].costs
        }
      })
      return totalCost
    },

    //
    getDifference(previous, next) {
      // Calculate the difference between previous and next measure states
      // For example:
      // previous: 1000 kg CO₂e
      // next: 800 kg CO₂e
      // difference: 200 kg CO₂e
      const difference = previous - next

      return {
        rawValue: difference,
        percentageValue: (Math.abs(difference) / previous) * 100,
        icon: difference < 0 ? '↑' : '↓',
      }
    },

    //
    onYearSelectionChange(year) {
      this.selectedYear = year
    },

    //
    onReset() {
      this.selectedYear = null
    },
  },
}
</script>

<style lang="scss" scoped>
.c-costs-overview {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-m);

  @media screen and (max-width: 1800px) {
    flex-direction: column;
  }

  & .costs-overview-chart-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;
    border: var(--box-border);
    border-radius: var(--box-radius);
    padding: var(--spacing-s);

    & > * {
      flex: 1;
    }

    & .costs-overview-chart {
      display: flex;
      flex-direction: column;
      min-height: 568px;
    }
  }

  & .costs-overview-table-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    overflow: hidden;

    & .overview-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & h2 {
        margin: var(--spacing-m) 0;
      }
    }

    & .measures-list {
      & span {
        margin-right: var(--spacing-xxs);
      }
    }
  }

  & .align-right {
    text-align: right;
    justify-content: flex-end;
  }

  & .align-left {
    text-align: left;
    justify-content: flex-start;
  }

  & .no-wrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .gray {
    color: #888;
  }

  & .no-data {
    text-align: center;
    padding: var(--spacing-m);
    border: var(--box-border);
    border-radius: var(--box-radius);
  }
}
</style>

<style lang="scss">
.c-costs-overview {
  & .costs-overview-chart-container {
    & .chart-container {
      flex: 1;
    }
  }

  & .costs-overview-table-container {
    & .detail-list {
      overflow: hidden;
      width: 100%;
      height: 100%;
      font-size: 13px;

      & ul {
        overflow: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        max-height: 500px;

        & li {
          display: flex;
          flex-direction: row;
          min-width: fit-content;
          width: 100%;
          align-items: stretch;

          &.summary-row {
            position: sticky;
            bottom: 0;
            background-color: var(--background-color);
          }

          & > * {
            flex: 1;
            width: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          & .id {
            flex: none;
            width: 80px;
          }

          & .name {
            min-width: 160px;
          }

          & .year {
            flex: none;
            width: 40px;
          }

          & .measures {
            min-width: 180px;
          }

          & .emissions {
            display: flex;
            flex-direction: row;
            gap: var(--spacing-xxs);
          }

          & .costs,
          & .emissions,
          & .reduction-costs {
            min-width: 140px;
          }
        }
      }
    }
  }
}
</style>
