<i18n>
{
  "de": {
    "yLabel": "Beheizte Fläche (m²)",
    "total": "Total"
  }
}
</i18n>

<template>
  <AsyncContentContainer :content-state="contentState" class="heating-type-chart">
    <template #default>
      <FaultsContainer :faults="projectionsByHeatingType.faulty" :portfolio="portfolio" />
      <BarChart :chart-data="chartData" :options="options" exportable />
    </template>
  </AsyncContentContainer>
</template>

<script>
import AsyncContentMixin from '@/components/shared/AsyncContentMixin.vue'
import ChartOptionsMixin from '@/pages/vuex-mixins/ChartOptionsMixin.vue'

import AsyncContentContainer from '@/components/shared/AsyncContentContainer.vue'
import FaultsContainer from '@/components/report/charts/FaultsContainer.vue'
import BarChart from '@/components/shared/charts/BarChart.vue'

import compassApi from '@/services/compass-api.js'
import colorPalettes from '@/services/color-palettes.js'

export default {
  mixins: [
    //
    AsyncContentMixin,
    ChartOptionsMixin,
  ],

  components: {
    BarChart,
    AsyncContentContainer,
    FaultsContainer,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    query: {
      type: Object,
    },
    scenario: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      projectionsByHeatingType: null,
    }
  },

  computed: {
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxis: {
            stacked: true,
          },
          yAxis: {
            stacked: true,
            beginAtZero: true,
            ticks: {
              callback: (value) => this.formatNumber(value),
            },
            title: {
              display: true,
              text: this.$t('yLabel'),
            },
          },
        },
        plugins: {
          tooltip: {
            mode: 'index',
            callbacks: {
              title: (items) => this.getTooltipTitleWithTotal(items, { title: this.$t('total'), unit: 'm²' }),
              label: (item) => this.getTooltipLabelWithPercentage(item, { unit: 'm²' }),
            },
          },
          legend: {
            position: 'bottom',
            align: 'start',
            reverse: true,
          },
        },
      }
    },

    chartData() {
      var datasets = []
      Object.keys(this.projectionsByHeatingType.projections).forEach((heatingTypeName, i) => {
        const heatingType = this.portfolio.heating_types.find((ht) => ht.name === heatingTypeName)
        if (heatingType) {
          let backgroundColor = colorPalettes.heatingColors[heatingType.name]
          if (backgroundColor === undefined) {
            backgroundColor = colorPalettes.energyCarrierColors[i % colorPalettes.energyCarrierColors.length]
          }
          datasets.push({
            carrierCode: heatingType.name,
            label: heatingType.default ? this.$t(`_heatingTypes.${heatingType.name}`) : heatingType.name,
            backgroundColor: backgroundColor,
            data: this.projectionsByHeatingType.projections[heatingTypeName].area,
            lineTension: 0.1,
            borderWidth: 0,
            pointRadius: 0,
            pointHitRadius: 10,
            borderColor: backgroundColor,
          })
        }
      })
      return {
        labels: this.projectionsByHeatingType.years,
        datasets: datasets,
      }
    },
  },

  watch: {
    portfolio() {
      this.refreshContent()
    },
    scenario() {
      this.refreshContent()
    },
    query() {
      this.refreshContent()
    },
  },

  methods: {
    async loadContentHandler(signal) {
      const query = JSON.stringify(this.query)
      this.projectionsByHeatingType = await compassApi.call(
        `/projections/${this.portfolio.id}/area/heating_type/${this.scenario.id}?query=${query}`,
        'GET',
        null,
        signal
      )
    },
  },
}
</script>

<style>
.heating-type-chart {
  min-height: 250px;
  width: 100%;
  border: var(--box-border);
  border-radius: var(--box-radius);
  padding: var(--spacing-s);
}
</style>
