<!--
Provides: chartData, chartDataLoading, chartDataReady
Requires: portfolio_id
-->
<script>
import { mapActions, mapState } from 'vuex'

export default {
  computed: {
    ...mapState('reportChartData', {
      reportChartData: (state) => state.reportChartData,
      reportChartDataLoaded: (state) => state.loaded,
    }),
  },

  watch: {
    portfolio_id() {
      this.__load({ portfolioId: this.portfolio_id })
    },
  },

  created() {
    this.__load({ portfolioId: this.portfolio_id })
  },

  methods: {
    ...mapActions({
      __load: 'reportChartData/load',
    }),
  },
}
</script>
