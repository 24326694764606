<i18n>
{
  "de": {
    "selectionComparison": "Übersicht",
    "scenarioComparison": "Szenarienvergleich",
    "sharePENonRenewable": "Anteil nicht erneuerbarer Primärenergie",
    "groupByUsage": "Nach Verwendungszweck",
    "groupByCarrier": "Nach Energieträger",
    "peMeasuredChart": "Messwerte",
    "peParetoChart": "Reduktion nach Liegenschaft"
  }
}
</i18n>

<template>
  <Tabs :group="`${kpiType}-section-tabs`" class="c-kpi-section">
    <Tab :title="$t('selectionComparison')">
      <KpiCompareSelectionChart
        :key="`${kpiType}-selection-chart`"
        class="chart"
        :kpi-type="kpiType"
        :portfolio="portfolio"
        :query="query"
        :scenario="scenario"
      />
    </Tab>
    <Tab :title="$t('scenarioComparison')">
      <KpiCompareScenarioChart
        :key="`${kpiType}-scenarios-chart`"
        class="chart"
        :kpi-type="kpiType"
        :portfolio="portfolio"
        :query="query"
      />
    </Tab>
    <Tab :title="$t('groupByUsage')">
      <KpiByUsageChart
        :key="`${kpiType}-by-usage-chart`"
        class="chart"
        :kpi-type="kpiType"
        :portfolio="portfolio"
        :query="query"
        :scenario="scenario"
      />
    </Tab>
    <Tab :title="$t('groupByCarrier')">
      <KpiByCarrierChart
        :key="`${kpiType}-by-carrier-chart`"
        class="chart"
        :kpi-type="kpiType"
        :portfolio="portfolio"
        :query="query"
        :scenario="scenario"
      />
    </Tab>
    <Tab :title="$t('peMeasuredChart')">
      <MeasuredKpiChart :key="`${kpiType}-measured-chart`" :kpi-type="kpiType" :portfolio="portfolio" :query="query" />
    </Tab>
    <Tab :title="$t('peParetoChart')">
      <KpiParetoChart
        :key="`${kpiType}-pareto-chart`"
        :kpi-type="kpiType"
        :scenario="scenario"
        :report-chart-data="reportChartData"
        @click="onParetoClick"
      />
    </Tab>
    <Tab v-if="kpiType === 'penr'" :title="$t('sharePENonRenewable')">
      <KpiSharePENRChart
        :key="`penr-share-chart`"
        class="chart"
        :portfolio="portfolio"
        :query="query"
        :scenario="scenario"
      />
    </Tab>
  </Tabs>
</template>

<script>
import { mapGetters } from 'vuex'

import Tabs from '@/components/shared/Tabs.vue'
import Tab from '@/components/shared/Tab.vue'
import KpiCompareSelectionChart from '@/components/report/charts/KpiCompareSelectionChart.vue'
import KpiCompareScenarioChart from '@/components/report/charts/KpiCompareScenarioChart.vue'
import KpiByUsageChart from '@/components/report/charts/KpiByUsageChart.vue'
import KpiByCarrierChart from '@/components/report/charts/KpiByCarrierChart.vue'
import MeasuredKpiChart from '@/components/report/charts/MeasuredKpiChart.vue'
import KpiParetoChart from '@/components/report/charts/KpiParetoChart.vue'
import KpiSharePENRChart from '@/components/report/charts/KpiSharePENRChart.vue'

export default {
  components: {
    Tabs,
    Tab,
    KpiCompareSelectionChart,
    KpiCompareScenarioChart,
    KpiByUsageChart,
    KpiByCarrierChart,
    MeasuredKpiChart,
    KpiParetoChart,
    KpiSharePENRChart,
  },

  props: {
    portfolio: {
      type: Object,
    },
    scenario: {
      type: Object,
    },
    kpiType: {
      type: String, // pe, per, s1e, s2e, s12e, ...
      required: true,
    },
    reportChartData: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      query: 'query/get',
    }),
  },

  methods: {
    onParetoClick(building) {
      this.$router.push({
        name: 'buildingDetails',
        params: { portfolio_id: this.portfolio.id, building_id: building.building_id },
      })
    },
  },
}
</script>
