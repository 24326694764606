<i18n>
{
  "de": {
    "yLabel": "Kapital (CHF)",
    "types": {
      "subsidies": "Einmalvergütung",
      "operation_costs": "Betriebskosten",
      "revenue_ownuse": "Reduktion Netzbezug",
      "revenue_feedin": "Rückspeisung",
      "investment": "Investitionskosten"
    }
  }
}
</i18n>

<template>
  <div class="scenario-pv-npv-chart">
    <BarChart :chart-data="chartData" :options="options" exportable />
  </div>
</template>

<script>
import ChartOptionsMixin from '@/pages/vuex-mixins/ChartOptionsMixin.vue'

import BarChart from '@/components/shared/charts/BarChart.vue'

import colorPalettes from '@/services/color-palettes.js'

export default {
  mixins: [ChartOptionsMixin],

  components: {
    BarChart,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    reportChartData: {
      type: Array,
      required: true,
    },
  },

  computed: {
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        /* aspectRatio: 3, */
        scales: {
          xAxis: {
            stacked: true,
          },
          yAxis: {
            stacked: true,
            beginAtZero: true,
            ticks: {
              callback: (value) => this.formatNumber(value, 0),
            },
            title: {
              display: true,
              text: this.$t('yLabel'),
            },
          },
        },
        plugins: {
          tooltip: {
            mode: 'index',
            callbacks: {
              title: (items) =>
                this.getTooltipTitleWithTotal(items, { title: items[0].label, unit: 'CHF', suffix: false }),
              label: (item) => this.getTooltipLabel(item, { unit: 'CHF' }),
            },
          },
          legend: {
            position: 'bottom',
            align: 'start',
            reverse: true,
          },
        },
      }
    },

    buildings() {
      return [...this.reportChartData.filter((b) => b.scenarios_data !== null)]
    },

    chartData() {
      let datasets = []
      datasets.push({
        label: this.$t(`types.investment`),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.buildings.reduce((pv, cv) => {
          return this.portfolio.scenarios.map((s, idx) => {
            const r = cv.scenarios_data.find((mt) => mt.scenario_id === s.id)
            return (
              (pv === undefined ? 0 : pv[idx]) -
              (r.pv_npv_details === null ? 0 : r.pv_npv_details.cashflow_pv.investment.reduce((a, sum) => a + sum, 0))
            )
          })
        }, undefined),
        backgroundColor: colorPalettes.pvNpvColors['investment'],
        borderColor: colorPalettes.pvNpvColors['investment'],
        stack: 'SPENDING',
      })
      datasets.push({
        label: this.$t(`types.subsidies`),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.buildings.reduce((pv, cv) => {
          return this.portfolio.scenarios.map((s, idx) => {
            const r = cv.scenarios_data.find((mt) => mt.scenario_id === s.id)
            return (
              (pv === undefined ? 0 : pv[idx]) +
              (r.pv_npv_details === null ? 0 : r.pv_npv_details.cashflow_pv.subsidies.reduce((a, sum) => a + sum, 0))
            )
          })
        }, undefined),
        backgroundColor: colorPalettes.pvNpvColors['subsidies'],
        borderColor: colorPalettes.pvNpvColors['subsidies'],
        stack: 'INCOME',
      })
      datasets.push({
        label: this.$t(`types.revenue_ownuse`),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.buildings.reduce((pv, cv) => {
          return this.portfolio.scenarios.map((s, idx) => {
            const r = cv.scenarios_data.find((mt) => mt.scenario_id === s.id)
            return (
              (pv === undefined ? 0 : pv[idx]) +
              (r.pv_npv_details === null
                ? 0
                : r.pv_npv_details.cashflow_pv.revenue_ownuse.reduce((a, sum) => a + sum, 0))
            )
          })
        }, undefined),
        backgroundColor: colorPalettes.pvNpvColors['revenue_ownuse'],
        borderColor: colorPalettes.pvNpvColors['revenue_ownuse'],
        stack: 'INCOME',
      })
      datasets.push({
        label: this.$t(`types.revenue_feedin`),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.buildings.reduce((pv, cv) => {
          return this.portfolio.scenarios.map((s, idx) => {
            const r = cv.scenarios_data.find((mt) => mt.scenario_id === s.id)
            return (
              (pv === undefined ? 0 : pv[idx]) +
              (r.pv_npv_details === null
                ? 0
                : r.pv_npv_details.cashflow_pv.revenue_feedin.reduce((a, sum) => a + sum, 0))
            )
          })
        }, undefined),
        backgroundColor: colorPalettes.pvNpvColors['revenue_feedin'],
        borderColor: colorPalettes.pvNpvColors['revenue_feedin'],
        stack: 'INCOME',
      })
      datasets.push({
        label: this.$t(`types.operation_costs`),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.buildings.reduce((pv, cv) => {
          return this.portfolio.scenarios.map((s, idx) => {
            const r = cv.scenarios_data.find((mt) => mt.scenario_id === s.id)
            return (
              (pv === undefined ? 0 : pv[idx]) -
              (r.pv_npv_details === null
                ? 0
                : r.pv_npv_details.cashflow_pv.operation_costs.reduce((a, sum) => a + sum, 0))
            )
          })
        }, undefined),
        backgroundColor: colorPalettes.pvNpvColors['operation_costs'],
        borderColor: colorPalettes.pvNpvColors['operation_costs'],
        stack: 'SPENDING',
      })
      return {
        labels: this.portfolio.scenarios.map((s) => s.description),
        datasets: datasets,
      }
    },
  },
}
</script>

<style>
.scenario-pv-npv-chart {
  min-height: 250px;
  width: 100%;
  border: var(--box-border);
  border-radius: var(--box-radius);
  padding: var(--spacing-s);
}
</style>
