<i18n>
{
  "de": {
    "investmentCostsComparisson": "Szenarienvergleich",
    "investmentCostsPath": "Übersicht",
    "pvNpvComparisson": "Wirtschaftlichkeit Photovoltaik"
  }
}
</i18n>

<template>
  <Tabs group="costs-section-tabs" class="costs-section">
    <Tab key="investmentCostsPath" :title="$t('investmentCostsPath')">
      <CostsOverview key="cost-overview" :portfolio="portfolio" :scenario="scenario" />
    </Tab>
    <Tab key="investmentCostsComparisson" :title="$t('investmentCostsComparisson')">
      <ScenarioCostChart key="scenario-cost-chart" :portfolio="portfolio" :report-chart-data="reportChartData" />
    </Tab>
    <Tab v-if="portfolio.enabled_features.PV_PROFITABILITY" key="pvNpvComparisson" :title="$t('pvNpvComparisson')">
      <ScenarioPvNpvChart key="scenario-pv-npv-chart" :portfolio="portfolio" :report-chart-data="reportChartData" />
    </Tab>
  </Tabs>
</template>

<script>
import Tabs from '@/components/shared/Tabs.vue'
import Tab from '@/components/shared/Tab.vue'
import ScenarioCostChart from '@/components/report/charts/ScenarioCostChart.vue'
import ScenarioPvNpvChart from '@/components/report/charts/ScenarioPvNpvChart.vue'
import CostsOverview from '@/components/report/sections/CostsOverview.vue'

export default {
  components: {
    CostsOverview,
    ScenarioCostChart,
    ScenarioPvNpvChart,
    Tabs,
    Tab,
  },

  props: {
    portfolio: {
      type: Object,
    },
    scenario: {
      type: Object,
    },
    reportChartData: {
      type: Array,
      required: true,
    },
  },
}
</script>
