<i18n>
{
  "de": {
    "yLabel": "Investitionskosten (CHF)",
    "measureWINDOWS": "Fensterersatz",
    "measureROOF": "Sanierung Dach",
    "measureBASEMENT": "Sanierung Kellerdecke",
    "measureWALLS": "Sanierung Fassade",
    "measureHEATING": "Heizungsersatz",
    "measurePV": "Installation Photovoltaik",
    "measureElectricity": "Änderung Netzanschluss"
  }
}
</i18n>

<template>
  <div class="scenario-cost-chart">
    <BarChart :chart-data="chartData" :options="options" exportable />
  </div>
</template>

<script>
import ChartOptionsMixin from '@/pages/vuex-mixins/ChartOptionsMixin.vue'

import BarChart from '@/components/shared/charts/BarChart.vue'

import colorPalettes from '@/services/color-palettes.js'

export default {
  mixins: [
    //
    ChartOptionsMixin,
  ],

  components: {
    BarChart,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    reportChartData: {
      type: Array,
      required: true,
    },
  },

  computed: {
    buildings() {
      return this.reportChartData.filter((b) => b.scenarios_data !== null)
    },

    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        /* aspectRatio: 3, */
        scales: {
          xAxis: {
            stacked: true,
          },
          yAxis: {
            stacked: true,
            beginAtZero: true,
            ticks: {
              callback: (value) => this.formatNumber(value, 0),
            },
            title: {
              display: true,
              text: this.$t('yLabel'),
            },
          },
        },
        plugins: {
          tooltip: {
            mode: 'index',
            callbacks: {
              title: (items) => this.getTooltipTitleWithTotal(items, { title: items[0].label, unit: 'CHF' }),
              label: (item) => this.getTooltipLabel(item, { unit: 'CHF' }),
            },
          },
          legend: {
            position: 'bottom',
            align: 'start',
          },
        },
      }
    },

    chartData() {
      // const electricityCosts =
      //   this.buildings.reduce((a, b) => {
      //     return this.portfolio.scenarios.map((s, idx) => {
      //       const r = b.scenarios_data.find((mt) => mt.scenario_id === s.id)
      //       return (a === undefined ? 0 : a[idx]) + r.total_costs.electricity
      //     })
      //   }, undefined) || 0
      const heatingCosts =
        this.buildings.reduce((a, b) => {
          return this.portfolio.scenarios.map((s, idx) => {
            const r = b.scenarios_data.find((mt) => mt.scenario_id === s.id)
            return (a === undefined ? 0 : a[idx]) + r.total_costs.heating
          })
        }, undefined) || []
      const pvCosts =
        this.buildings.reduce((a, b) => {
          return this.portfolio.scenarios.map((s, idx) => {
            const r = b.scenarios_data.find((mt) => mt.scenario_id === s.id)
            return (a === undefined ? 0 : a[idx]) + r.total_costs.pv
          })
        }, undefined) || []
      const wallsCosts =
        this.buildings.reduce((a, b) => {
          return this.portfolio.scenarios.map((s, idx) => {
            const r = b.scenarios_data.find((mt) => mt.scenario_id === s.id)
            return (a === undefined ? 0 : a[idx]) + r.total_costs.walls
          })
        }, undefined) || []
      const windowsCosts =
        this.buildings.reduce((a, b) => {
          return this.portfolio.scenarios.map((s, idx) => {
            const r = b.scenarios_data.find((mt) => mt.scenario_id === s.id)
            return (a === undefined ? 0 : a[idx]) + r.total_costs.windows
          })
        }, undefined) || []
      const roofCosts =
        this.buildings.reduce((a, b) => {
          return this.portfolio.scenarios.map((s, idx) => {
            const r = b.scenarios_data.find((mt) => mt.scenario_id === s.id)
            return (a === undefined ? 0 : a[idx]) + r.total_costs.roof
          })
        }, undefined) || []
      const basementCosts =
        this.buildings.reduce((a, b) => {
          return this.portfolio.scenarios.map((s, idx) => {
            const r = b.scenarios_data.find((mt) => mt.scenario_id === s.id)
            return (a === undefined ? 0 : a[idx]) + r.total_costs.basement
          })
        }, undefined) || []

      const allCosts = [heatingCosts, pvCosts, wallsCosts, windowsCosts, roofCosts, basementCosts]
        .reduce((a, b) => (a ? a.map((v, i) => v + b[i]) : b), undefined)
        .reduce((a, b) => a + b, 0)

      let datasets = []

      // TODO: add zones (currently not possible since zone actions don't have costs)

      // TODO: add electricity costs
      // if (electricityCosts > 0) {
      //   datasets.push({
      //     label: this.$t('measureElectricity'),
      //     lineTension: 0.1,
      //     borderWidth: 0,
      //     pointRadius: 0,
      //     pointHitRadius: 10,
      //     data: this.buildings.reduce((pv, cv) => {
      //       return this.portfolio.scenarios.map((s, idx) => {
      //         const r = cv.scenarios_data.find((mt) => mt.scenario_id === s.id)
      //         return (pv === undefined ? 0 : pv[idx]) + r.total_costs.electricity
      //       })
      //     }, undefined),
      //     backgroundColor: colorPalettes.measureColors['ELECTRICITY'],
      //     borderColor: colorPalettes.measureColors['ELECTRICITY'],
      //   })
      // }
      if (heatingCosts.some((c) => c > 0)) {
        datasets.push({
          label: this.$t('measureHEATING'),
          lineTension: 0.1,
          borderWidth: 0,
          pointRadius: 0,
          pointHitRadius: 10,
          data: heatingCosts,
          backgroundColor: colorPalettes.measureColors['HEATING'],
          borderColor: colorPalettes.measureColors['HEATING'],
        })
      }
      if (pvCosts.some((c) => c > 0)) {
        datasets.push({
          label: this.$t('measurePV'),
          lineTension: 0.1,
          borderWidth: 0,
          pointRadius: 0,
          pointHitRadius: 10,
          data: pvCosts,
          backgroundColor: colorPalettes.measureColors['PV'],
          borderColor: colorPalettes.measureColors['PV'],
        })
      }
      if (wallsCosts.some((c) => c > 0)) {
        datasets.push({
          label: this.$t('measureWALLS'),
          lineTension: 0.1,
          borderWidth: 0,
          pointRadius: 0,
          pointHitRadius: 10,
          data: wallsCosts,
          backgroundColor: colorPalettes.measureColors['WALLS'],
          borderColor: colorPalettes.measureColors['WALLS'],
        })
      }
      if (windowsCosts.some((c) => c > 0)) {
        datasets.push({
          label: this.$t('measureWINDOWS'),
          lineTension: 0.1,
          borderWidth: 0,
          pointRadius: 0,
          pointHitRadius: 10,
          data: windowsCosts,
          backgroundColor: colorPalettes.measureColors['WINDOWS'],
          borderColor: colorPalettes.measureColors['WINDOWS'],
        })
      }
      if (roofCosts.some((c) => c > 0)) {
        datasets.push({
          label: this.$t('measureROOF'),
          lineTension: 0.1,
          borderWidth: 0,
          pointRadius: 0,
          pointHitRadius: 10,
          data: roofCosts,
          backgroundColor: colorPalettes.measureColors['ROOF'],
          borderColor: colorPalettes.measureColors['ROOF'],
        })
      }
      if (basementCosts.some((c) => c > 0)) {
        datasets.push({
          label: this.$t('measureBASEMENT'),
          lineTension: 0.1,
          borderWidth: 0,
          pointRadius: 0,
          pointHitRadius: 10,
          data: basementCosts,
          backgroundColor: colorPalettes.measureColors['BASEMENT'],
          borderColor: colorPalettes.measureColors['BASEMENT'],
        })
      }

      return {
        labels: this.portfolio.scenarios.map((s) => s.description),
        datasets:
          allCosts > 0
            ? datasets
            : [
                // Empty placeholder dataset to show chart
                {
                  label: '',
                  data: this.buildings.map(() => 0),
                  backgroundColor: 'rgba(0, 0, 0, 0)',
                  borderColor: 'rgba(0, 0, 0, 0)',
                },
              ],
      }
    },
  },
}
</script>

<style>
.scenario-cost-chart {
  min-height: 250px;
  width: 100%;
  border: var(--box-border);
  border-radius: var(--box-radius);
  padding: var(--spacing-s);
}
</style>
