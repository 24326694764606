<i18n>
{
  "de": {
    "allBuildings": "Gesamtportfolio ({n} Liegenschaften)",
    "selectedBuildings": "Auswahl ({n} Liegenschaften)"
  }
}
</i18n>

<template>
  <AsyncContentContainer :content-state="contentState" class="c-kpi-penr-share-chart">
    <template #default>
      <FaultsContainer :faults="projectionsByScenario.faulty" :portfolio="portfolio" />
      <LineChart :chart-data="chartData" :options="options" exportable />
    </template>
  </AsyncContentContainer>
</template>

<script>
import { mapGetters } from 'vuex'

import AsyncContentMixin from '@/components/shared/AsyncContentMixin.vue'
import ChartOptionsMixin from '@/pages/vuex-mixins/ChartOptionsMixin.vue'

import AsyncContentContainer from '@/components/shared/AsyncContentContainer.vue'
import FaultsContainer from '@/components/report/charts/FaultsContainer.vue'
import LineChart from '@/components/shared/charts/LineChart.vue'

import compassApi from '@/services/compass-api.js'

export default {
  mixins: [
    //
    AsyncContentMixin, // provides contentState
    ChartOptionsMixin, // provides getTooltipLabelWithReductionString
  ],

  components: {
    LineChart,
    AsyncContentContainer,
    FaultsContainer,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    query: {
      type: Object,
    },
    scenario: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      projectionsByScenario: null,
      projectionsByScenarioAll: null,
      roundPrecision: 1,
    }
  },

  computed: {
    ...mapGetters({
      __getEnhancedSustainabilityIndicator: 'portfolio/getEnhancedSustainabilityIndicatorByIdentifier',
    }),

    sustainabilityIndicator() {
      return this.__getEnhancedSustainabilityIndicator('penr')
    },

    yLabel() {
      return `Anteil ${this.getIndicatorName} (%)`
    },

    getIndicatorName() {
      if (!this.sustainabilityIndicator) {
        return this.kpiType
      }
      return this.sustainabilityIndicator.name
    },

    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        /* aspectRatio: 3, */
        scales: {
          yAxis: {
            beginAtZero: true,
            title: {
              display: true,
              text: this.yLabel,
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (item) =>
                this.getTooltipLabelWithReductionString(item, { precision: this.roundPrecision, unit: '%' }),
            },
          },
          legend: {
            position: 'bottom',
            align: 'start',
            display: true,
            reverse: true,
          },
        },
      }
    },

    chartData() {
      var datasets = []

      //
      if (this.projectionsByScenario.nbuildings !== this.projectionsByScenarioAll.nbuildings) {
        datasets.push({
          label: this.$t('allBuildings', { n: this.projectionsByScenarioAll.nbuildings }),
          borderColor: 'rgba(50,0,200,0.3)',
          pointBackgroundColor: '#ffffff',
          pointHoverBackgroundColor: '#ffffff',
          pointHoverBorderWidth: 2,
          pointHoverRadius: 4,
          pointRadius: 2,
          borderWidth: 4,
          fill: false,
          lineTension: 0,
          pointHitRadius: 20,
          data: this.projectionsByScenarioAll.projections[this.scenario.id].value,
        })
      }

      //
      datasets.push({
        label: this.$t(
          this.projectionsByScenario.nbuildings !== this.projectionsByScenarioAll.nbuildings
            ? 'selectedBuildings'
            : 'allBuildings',
          { n: this.projectionsByScenario.nbuildings }
        ),
        borderColor: 'rgba(50,0,200,0.9)',
        pointBackgroundColor: '#ffffff',
        pointHoverBackgroundColor: '#ffffff',
        pointHoverBorderWidth: 2,
        pointHoverRadius: 4,
        pointRadius: 2,
        borderWidth: 4,
        fill: false,
        lineTension: 0,
        pointHitRadius: 20,
        data: this.projectionsByScenario.projections[this.scenario.id].value,
      })

      return {
        labels: this.projectionsByScenarioAll.years,
        datasets: datasets,
      }
    },
  },

  watch: {
    portfolio() {
      this.refreshContent()
    },
    scenario() {
      this.refreshContent()
    },
    query() {
      this.refreshContent()
    },
  },

  methods: {
    async loadContentHandler(signal) {
      const query = JSON.stringify(this.query)
      const projectionsPENRByScenario = await compassApi.call(
        `/projections/${this.portfolio.id}/penr/scenarios?query=${query}`,
        'GET',
        null,
        signal
      )
      const projectionsPEByScenario = await compassApi.call(
        `/projections/${this.portfolio.id}/pe/scenarios?query=${query}`,
        'GET',
        null,
        signal
      )
      this.projectionsByScenario = projectionsPENRByScenario
      for (const scenarioId in projectionsPENRByScenario.projections) {
        for (const idx in projectionsPENRByScenario.projections[scenarioId].value) {
          this.projectionsByScenario.projections[scenarioId].value[idx] =
            (100.0 * projectionsPENRByScenario.projections[scenarioId].value[idx]) /
            projectionsPEByScenario.projections[scenarioId].value[idx]
        }
      }
      // Projections for selection and all buildings
      const queryAll = JSON.stringify({})
      const projectionsPENRByScenarioAll = await compassApi.call(
        `/projections/${this.portfolio.id}/penr/scenarios?query=${queryAll}`,
        'GET',
        null,
        signal
      )
      const projectionsPEByScenarioAll = await compassApi.call(
        `/projections/${this.portfolio.id}/pe/scenarios?query=${queryAll}`,
        'GET',
        null,
        signal
      )
      this.projectionsByScenarioAll = projectionsPENRByScenarioAll
      for (const scenarioId in projectionsPENRByScenarioAll.projections) {
        for (const idx in projectionsPENRByScenarioAll.projections[scenarioId].value) {
          this.projectionsByScenarioAll.projections[scenarioId].value[idx] =
            (100.0 * projectionsPENRByScenarioAll.projections[scenarioId].value[idx]) /
            projectionsPEByScenarioAll.projections[scenarioId].value[idx]
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-kpi-penr-share-chart {
  min-height: 250px;
  width: 100%;
  border: var(--box-border);
  border-radius: var(--box-radius);
  padding: var(--spacing-s);
}
</style>
